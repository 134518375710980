import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostAccessoryPurchase, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { addDays, addMonths, format } from "date-fns";
import { SelectApprover } from "~/components/block/SelectApprover";
import { useHistory } from "react-router";

const AccessoryPurchase = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);

    // const [change_user_flag, setAnotherUser] = React.useState(false);
    const [selected_accessory_list, setSelectedAccessoryList] = React.useState([]);

    const accessory_list = [
        { id: 1, item: "ｱﾀﾞﾌﾟﾀ：AUKEY Minima 20W（R21P005W）1,980 円(税込)" },
        { id: 2, item: "ｹｰﾌﾞﾙ：USB-C - Lightning ｹｰﾌﾞﾙ(1 m)(MM0A3FE）2,480 円(税込)" },
        { id: 3, item: "ｲﾔﾌｫﾝ：EarPods Lightning ｺﾈｸﾀ 2016(MMTN2JA) 2,780 円(税込)" },
    ];

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        const hope_date = elem?.hope_date?.value;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }
        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        dispatch(
            PostAccessoryPurchase({
                tel: ApplicationState.selected_tel,
                accessory_name: selected_accessory_list,
                remarks: elem?.remarks?.value,
                hope_date: hope_date,
                sending_name: elem?.sending_name?.value,
                sending_post_code: elem?.sending_post_code?.value,
                sending_address: elem?.sending_address?.value,
                sending_tel: elem?.sending_tel?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });
    };

    // const [disabledCheck, setDisabledCheck] = React.useState(false);

    const after_month = (arg) => {
        const day = addMonths(new Date(), 1);
        const format_day = format(day, "yyyy-MM");
        return format_day;
    };

    const HandleClickAccessory = (accessory) => {
        if (selected_accessory_list.includes(accessory.item)) {
            setSelectedAccessoryList(selected_accessory_list.filter((checkedValue) => checkedValue !== accessory.item));
        } else {
            setSelectedAccessoryList([...selected_accessory_list, accessory.item]);
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h4">アクセサリ申請</Typography>
            電源アダプタ・電源ケーブル・イヤホンを購入する際の申請です
            <Box
                sx={{
                    pt: "1rem",
                }}
            >
                <Caution />
                <Typography variant="h5">①現在ご利用機種</Typography>
                <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                    <SerchModelData />
                </Box>

                <Typography variant="h5">②ご申請内容</Typography>
                <form onSubmit={HandleSubmit}>
                    <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                        <FormGroup name="accessory">
                            {accessory_list.map((accessory, i) => (
                                <FormControlLabel
                                    control={<Checkbox onClick={(e) => HandleClickAccessory(accessory)}></Checkbox>}
                                    label={accessory.item}
                                />
                            ))}
                        </FormGroup>
                        申請理由/特記事項
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label=""
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                        <Box sx={{ mt: 1 }}>納入希望月</Box>
                        <Box sx={{ mb: 1 }}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                type="month"
                                label=""
                                defaultValue={after_month()}
                                name="hope_date"
                                required
                            />
                        </Box>
                    </Box>

                    <InputDestination titleNum="③" />

                    <Typography variant="h5">承認者</Typography>
                    <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                        <SelectApprover />
                    </Box>
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </form>
            </Box>
        </React.Fragment>
    );
};

const Caution = () => {
    return (
        <React.Fragment>
            <div style={{ border: "1px solid #FF6600", margin: "5px 0 5px 0" }} className="textselect">
                <div style={{ margin: "5px 0 5px 5px" }}>
                    <span style={{ fontWeight: "bold", color: "#ff0000" }}>iPhoneはバッテリーの交換はできません</span>
                </div>
                <div style={{ margin: "0 0 5px 5px" }}>
                    バッテリー老化の場合は、<a href="Damage">&nbsp;故障申請&nbsp;</a>
                    から申請してください。
                </div>
            </div>
        </React.Fragment>
    );
};

export default AccessoryPurchase;
