import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    Checkbox,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Typography,
    TextField,
    Container,
    Button,
    Box,
    Radio,
    RadioGroup,
    Switch,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";

import { DataGrid, jaJP } from "@mui/x-data-grid";
import { width } from "@mui/system";

import { PostModelInfoChange, Application_set, GetModelData } from "~slices/Application";
import SelectUser from "~/components/block/SelectUser";
import { SelectModel } from "~/components/block/SelectModel";
import { SerchModelData } from "~/components/block/SerchModelData";

import { InputDestination } from "~/components/block/InputDestination";
import { SelectApprover } from "~/components/block/SelectApprover";

import { addDays, addMonths, format } from "date-fns";

import { useHistory } from "react-router";

const ModelInfoChange = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ApplicationState = useSelector((state) => state.Application);

    const [change_cost_code_flag, setChangeCostCodeFlag] = React.useState(false);
    const [change_usage_flag, setChangeUsageFlag] = React.useState(false);
    const [selected_user, setSelectedUser] = React.useState({});

    const HandleSubmit = (e) => {
        e.preventDefault();

        const elem = e.target.elements;

        if (change_cost_code_flag) {
            if (!elem?.cost_code?.value) {
                alert("経費負担コードを登録してください。");
                return;
            }
        }

        const now = new Date()
        const this_month = format(now, 'yyyy-MM')

        if (elem?.hope_date?.value < this_month) {
            alert("登録情報変更月に過去月は登録できません。");
            return;
        }







        // const model = elem?.model?.value;

        if (!ApplicationState.selected_tel) {
            alert("電話番号を登録してください。");
            return;
        }

        if (!ApplicationState.approver_name) {
            alert("承認者を登録してください。");
            return;
        }

        // if (elem?.hope_date) {
        //     alert("承認者を登録してください。");
        //     return;
        // }




        dispatch(
            PostModelInfoChange({
                tel: ApplicationState.selected_tel,
                remarks: elem?.remarks?.value,
                change_cost_code_flag: change_cost_code_flag,
                change_usage_flag: change_usage_flag,
                cost_code: elem?.cost_code?.value,
                // account_code: elem?.account_code?.value,
                hope_date: elem?.hope_date?.value + '-01',
                usage: elem?.usage?.value,
                approver_name: ApplicationState.approver_name,
                approver_email: ApplicationState.approver_email,
            })
        ).then((res) => {
            if (res.payload.result == "OK") {
                alert("申請が完了しました。");
                history.push("/");
            }
        });

    };

    const HandleSelectUser = (select_user) => {
        setSelectedUser(select_user);


    };

    // const [disabledCheck, setDisabledCheck] = React.useState(false);

    const after_day = (arg) => {
        // const day = addDays(new Date(), 10);
        const day = addMonths(new Date(), 0);

        const format_day = format(day, "yyyy-MM");

        return format_day;
        // // this_day.setDate(this_day.getDate() + 11);
        // this_day.setMonth(this_day.getMonth() + 1);
        // // const ten_day = today.setDate(today.getDate() + 10);

        // const year = this_day.getFullYear();
        // const month = this_day.getMonth();
        // const day = this_day.getDate();
        // const string_month = month.toString().padStart(2, "0");
        // return `${year}-${string_month}-${day}`;
    };

    return (
        <React.Fragment>
            <Typography variant="h4"> 登録情報変更</Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
                現在ご利用機種
            </Typography>
            <Box sx={{ background: "#ececec", p: 2, mb: 2 }}>
                <SerchModelData />
            </Box>
            <form onSubmit={HandleSubmit}>
                <Box sx={{ background: "#ececec", p: 2 }}>
                    <Box sx={{ mt: 1, mb: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="month"
                            label="登録情報変更月"
                            defaultValue={after_day()}
                            name="hope_date"
                        />
                    </Box>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={change_cost_code_flag}
                                onChange={(e) => setChangeCostCodeFlag(e.target.checked)}
                            />
                        }
                        label="経費負担を変更する"
                    />
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="経費負担コード"
                            name="cost_code"
                            disabled={!change_cost_code_flag}
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                    {/* <Box sx={{ mt: 2, mb: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            label="勘定コード"
                            disabled={!change_cost_code_flag}
                            name="account_code"

                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box> */}



                    <FormControlLabel
                        control={
                            <Switch
                                checked={change_usage_flag}
                                onChange={(e) => setChangeUsageFlag(e.target.checked)}
                            />
                        }
                        label="利用用途を変更する"
                    />
                    <Box>
                        ※課などでの共有端末や、使用者以外が利用する貸与端末の場合、利用用途をご記載ください。
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            sx={{ mt: "1rem" }}
                            type="text"
                            label="利用用途"
                            name="usage"
                            multiline
                            rows={1}
                            disabled={!change_usage_flag}
                            fullWidth />

                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            InputLabelProps={{ shrink: true, min: "2023-11-25" }}
                            type="text"
                            label="申請理由/特記事項"
                            name="remarks"
                            multiline
                            rows={4}
                            fullWidth
                        // helperText="※ 名前は部分一致で検索します。"
                        />
                    </Box>
                </Box>

                {change_cost_code_flag ?


                    < Box sx={{
                        margin: '8px',
                        padding: '8px',
                        color: 'white',
                        background: '#ff0808',
                        maxWidth: '-webkit-fit-content',
                        maxWidth: '-moz-fit-content',
                        maxWidth: 'fit-content',
                        fontWeight: 'bold',
                        border: 'solid 1rem yellow'
                    }}>
                        <Box>
                            ※経費負担コード変更先の部署は変更承諾済みですか？
                        </Box>
                        <FormControlLabel control={<Checkbox required />} label="はい。変更先の部署の承諾済みです。" />
                    </Box>
                    : ""}

                <Typography variant="h5">承認者</Typography>
                <Box sx={{ backgroundColor: "#ececec", p: "1rem", mt: 1 }}>
                    <SelectApprover />
                </Box>
                <Box
                    sx={{
                        pt: "1rem",
                    }}
                >
                    <Button variant="contained" size="large" sx={{ height: "2.5rem" }} type="submit">
                        申請
                    </Button>
                </Box>
            </form>
        </React.Fragment >
    );
};

export default ModelInfoChange;
